/* Stilovi za smoke-container */
.smoke--container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: 0; 
    background-color: transparent; 
}
  
  .smoke {
    position: absolute;
    bottom: -150px; 
    width: 600px;
    height: 300px;
    background: url('../images/s.png');
    background-size: cover;
    opacity: 1;
    animation: rise 60s infinite ease-in-out;
    pointer-events: none; 
  }
  
  
  @keyframes rise {
    0% {
      transform: translateY(0) scale(1);
      opacity: 0.1;   
    }

    25% {
        opacity: 1;
    }

    50% {
      opacity: 0.3;
    }

    60% {
      opacity: 0.2;
    }

    100% {
      transform: translateY(-400px) scale(5); 
      opacity: 0;
    }
  }

  @keyframes risea {
      0% {
        transform: translateY(0) scale(1);
      }
      50% {
        transform: scale(2);
        opacity: 0.7;
      }

      70% {
        opacity: 0.3;
      }
      100% {
        transform: translateY(-400px) scale(2); /* Dizanje i širenje */
        opacity: 0;
      }
  }
  
  
   .smoke:nth-of-type(1) {
    left: 2%;
    animation-duration: 12s;
    animation-delay: 1s;
  }
  
   .smoke:nth-of-type(2) {
    left: 10%;
    animation-duration: 14s;
    animation-delay: 0s;
  }
  
  .smoke:nth-of-type(3) {
    left: 25%;
    animation-duration: 16s;
    animation-delay: 3s;
  }

  .smoke:nth-of-type(4) {
    left: 40%;
    animation-duration: 9s;
    animation-delay: 0s;
  }
  
  .smoke:nth-of-type(5) {
    left: 70%;
    animation-duration: 20s;
    animation-delay: 3s;
  }
  
  .smoke:nth-of-type(6) {
    left: 93%;
    animation-duration: 6s;
    animation-delay: 1s;
  }
