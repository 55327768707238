@keyframes scroll {
  0%  {
    transform: translateX(0) }
  50% {
    transform: translateX(-50%)
  }
  100% {
    transform: translateX(0)
  }
}

@media (max-width:768px) and (orientation:portrait) {
  
   .modal-content {
     max-width:100% !important;
   }

   .modal-navigation {
     top:80% !important;
   }

}

@media (min-width: 769px) and (max-width: 1330px) and (orientation: portrait) {
   
  .modal-content {
    max-width: 80% !important;
  }
  
  .modal-navigation {
    width: 95% !important;
  }
   
}

:root {
  --animation-duration: 7s; 
}

.media--container {
    width:100%;
    padding: 5px;
    background-color: rgba(0, 0, 0, 0.5);
    white-space: nowrap;
         
}

.image--style {
  margin: 150px auto 350px auto;
  
}


.image--wrapper {
    display: flex;
    animation: scroll var(--animation-duration) linear infinite; 
    animation-delay: 1s; 
    
}

.video--wrapper {
   padding: 10px 0 10px 0;
   overflow-x: scroll;
   scroll-behavior: smooth;
   cursor: grab;
}

::-webkit-scrollbar {
  display: none;
}


.image--wrapper img {
    margin-right: 10px;
    width: 18em;
    height: 12em;
    object-fit: cover;
    transition: 1s ease-in-out;
   
}

.video--wrapper video {
  margin-right: 20px;
  width: 27em;
  height: 18em;
  object-fit: cover;
 
}


.image--wrapper img:hover {
  cursor: pointer;
  transform: scale(1.3);
  z-index: 1;
  margin-right: -5px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.288);
  
}

.video--wrapper video:hover {
  box-shadow: 0px 0px 16px rgba(255, 255, 255, 0.5);
}



.paused .image--wrapper {
    animation-play-state: paused;
  }



  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }
  
  .modal-content {
    max-width: 75%;
    max-height: 80%;
    object-fit: contain;
  }
  
  .close {
    position: absolute;
    top: 20px;
    right: 30px;
    color: white;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .modal-navigation {
    position: absolute;
    top: 50%;
    width: 85%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
    
  }
  
  .modal-navigation button {
    background-color: tan;
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    font-size: 20px;
    animation: none;
  }

  .modal-navigation .prev {
    left: -50px; 
  }

  .modal-navigation .next {
    right: -50px; 
  }