* {
  box-sizing: border-box;
}


@keyframes titleanim {
    from {left: -50px;}
    to {left: 0px;}
}

@keyframes fromright {
   from {right: -50px;}
   to {right: 0px;}
}

@keyframes tittlebottom {
    from {bottom: -30px;}
    to {bottom: 0px}
}

@keyframes frombottom {
    from {bottom: -200px;}
    to {bottom: 0px}
}

@keyframes fromtop {
  from {top:-30px}
  to {top:0px}
}

@keyframes showup {
  0% {opacity:0.1;}
  20% {opacity:0.3;}
  80% {opacity:0.5;}
  100% {opacity:1;}
}


@keyframes glow {
  0% {
    text-shadow: 0 0 5px white, 0 0 10px white, 0 0 15px white, 
                 0 0 20px rgb(94, 92, 0), 0 0 30px rgb(94, 92, 0), 0 0 40px rgb(94, 92, 0);
}
50% {
    text-shadow: 0 0 5px white, 0 0 15px white, 0 0 20px white, 
                 0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
}
  
100%  { text-shadow: 0 0 5px white, 0 0 10px white, 0 0 15px white, 
                   0 0 20px rgb(94, 92, 0), 0 0 30px rgb(94, 92, 0), 0 0 40px rgb(94, 92, 0);
}
  
}

@keyframes titleglow {
  0% {
    text-shadow: 0 0 5px white, 0 0 11px white, 0 0 12px white, 
                 0 0 10px tan, 0 0 11px tan, 0 0 12px tan;
}
50% {
    text-shadow: 0 0 5px white, 0 0 15px white, 0 0 20px white, 
                 0 0 25px rgb(240, 216, 150), 0 0 35px rgb(240, 216, 150), 0 0 45px rgb(240, 216, 150);
}
  
100%  { text-shadow: 0 0 5px white, 0 0 11px white, 0 0 12px white, 
                   0 0 10px rgb(168, 148, 105), 0 0 11px rgb(168, 148, 105), 0 0 12px rgb(168, 148, 105);
}
  
}

@keyframes rotate {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

main {
  width: 100%;
  position: relative;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid black;
  backdrop-filter: blur(3px);
}

.navbar { 
  display: flex;
  justify-content: flex-start;
  padding: 1em 2em;
  transition: 0.5s;
}

.scrolled {
  padding: 0.5em 2em;
  transition: 1s ease-in-out;
}

.scrolled img {
  width: 3em;
  transition: 1s ease-in-out;
}

.navbar .logo {
  max-width: 5em;
  animation: rotate 2.5s linear infinite;
  transform-origin: center;
  transform-style: preserve-3d;
  cursor: pointer;
}

.navbar .logo:hover {
  animation-play-state: paused;
}


.navbar--list {
  display:flex;
  align-items: center;
  margin: 0 0 0 auto;
  font-size: 2em;
  font-family: Oswald;
  font-weight: 700;
}


button {
  padding: 12px;
  cursor: pointer;
  border-radius: 5px;
  border: none;
}

.formats {
  font-size: 0.8em;
  color: rgb(182, 182, 182);
  text-align: left;
}

li {
  list-style-type: none;
}

.navbar--link:hover {
  transform: scale(1.05);
  animation: glow 1.5s infinite alternate;
  transition: 0.5s;
}

.navbar--link {
  color: #301e1e;
  -webkit-text-stroke: 0.7px white;
  text-decoration: none;
  display: flex;
  padding-right: 1em;
  padding-left: 1em;
}

.navbar--link--active {
  color: white;
  text-decoration: none;
  padding-right: 1em;
  padding-left: 1em;
}

.hamburger {
  display: none;
  font-size: 1.5rem;
  font-weight: 800;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  color: tan;
  z-index: 11;
  width: 4rem;
  margin: 0 0 0 auto;
  animation: showup 0.5s;
  justify-content: center;
  align-items: center;
}

.hamburger:hover {
  background-color: tan;
  color: black;
}


@media (max-width: 768px) and (orientation: portrait) {

  .onama--background {
    background-image: url('./images/Designer4m.jpg') !important;
  }

  .second--bck {
    background-image: url('./images/Designer18m.jpeg') !important;
    background-position: center;
  }

  .third--bck {
    background-image: url('./images/Designer5m.jpg') !important;
    background-position: center;
  }

  .fourth--bck {
    background-image: url('./images/Designer16m.jpeg') !important;
    background-position: center;
  }

  .clanstvo--background {
    background-image: url('./images/Designer3m.jpg') !important;
    background-position: center;
  }

  iframe {
    width: 300px;
  }

  .opis--o--nama, .news, .donacija--opis, .scroll--container, .form--registration {
    width: 90% !important;
    margin: 1em auto;
    padding-top: 5px;
    text-align: left;
   }

  .navbar .logo {
    width: 3rem;
  }
  .navbar--list {
    display: none;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    top: 100%; 
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(10px);
    width: 100%;
    padding: 1rem;
    transition: max-height 0.3s ease-in-out;
    z-index: 12 !important;
  }

  .hidden {
    display: none;
  }

  .main--title {
    font-size: 3em !important;
    transform: translateY(-50%);
  }

  .title {
    max-width: 90%;
    margin: auto auto 2em auto;
  }


  .navbar--list.open {
    display: flex;
    max-height: 700px;
      
  }

  .hamburger {
    
    position: relative;
    width: 3rem;
    height: 3rem;
  }

  .main--mid {
    justify-content: center;
    margin: 10px auto 0 auto;
    left: 50% !important;
    transform: translateX(-50%);
  }

  .soc--links {
    flex-direction: row !important;
    align-items: center;
    animation: showup 1s;
    padding: 1em;
  }

  .soc--links a {
    margin: 0 5px;
  }

  .form--registration {
    width: 100%;
  }

 .contact {
  padding: 20px !important;
  flex-direction: column !important;
 }

 .contact .logo {
  width: 10em !important;
  margin: 50px auto 10px auto;
 }

  .pocetna--background,
  .nastim--background .clanstvo--background, .donacija--background, .scnd--bck {
  background-attachment: local !important;
 }

 .flag--select {
  width: 3.6em;
  height: 1.7em;
  font-size: 0.8em;
  margin-right: 0.05em !important;
}

.flag img{
  width: 1.9em !important;
}

p {
  font-size: 1.1em !important;
  }

  .grid--container {
    grid-template-columns: auto auto !important;
    gap: 1em !important;
  }

  .scnd--bck {
    padding: 2em !important;
  }

  .card {
    
    max-width: 10em !important;
    padding: 3px !important;
    position: relative;
  }
  
  .card--image {
    width: 7em !important;
    height: 8em !important;
  }
  
  .card--image img {
    height: 8em !important;
    width: 7em !important;
  }

  .card--text p {
    font-size: 0.6em !important;
  }

  .nasi--clanovi--div {
    z-index: 2 !important;
   
  }

  .nasi--clanovi--title {
    font-size: 3em !important;
  }  

  .arrow-container {
    width: 4em !important;
  }

  .layer3 {
    background-repeat: no-repeat !important;
  }
  
  .image--wrapper img {
    width: 6em !important;
  }

  .sponsor--container {
    grid-template-columns: auto !important;
    gap: 1em !important;
  }

  .sponsor {
    max-width: 20em;
    max-height: 10em;
  }
  .sponsor img{

    max-width: 20em !important;
}

.tim--slika--okvir {
  width: 100%;
  height: 30vh;
  position: relative;
  top: 0;
}

.tim--slika {
  height: auto !important;
}

.nasi--clanovi--div .title {
  padding-top: 0vh;
}


}

@media (max-width: 768px) and (orientation: landscape) {

  .main--title { 
    font-size: 3em !important;
  }

  .title {
    padding-top: 25vh !important;
  }

  .navbar, .scrolled {
    padding: 0.2em 1em;
  }

  .hamburger {
    width: 2em;
    height: 2em;
    font-size: 1em;
  }

  .flag--select {
    height: 1.7em;
    font-size: 0.6em;
  }

  .flag, .flag img {
    width: 1.5em !important;
  }

  .opis--o--nama, .news, .donacija--opis, .scroll--container, .form--registration {
    width: 70% !important;
    margin: 0 auto;
    padding-top: 5px;
   }

  .navbar--list {
    display: none;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    top: 100%; 
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    width: 40%;
    padding: 1rem;
    transition: max-height 0.3s ease-in-out;
    z-index: 12 !important;
  }

  .navbar--list.open {
    display: flex;
    max-height: 700px;
    
  }



  .flag--container {
    position: relative;
    
  }



  .navbar .logo {
    width: 2rem;
  }

  .main--mid {
    width: 3em !important;
    transform: translateY(-100%) !important;
  }

  .soc--links {
    padding: 0.5em !important;
  }

  .soc--links img{
    width: 1.5em;
  }
  
  .contact {
    padding: 30px !important;
  }

  .contact .logo {
    width: 10em !important;
  }
  
  p {
    font-size: 1em !important;
    }
  
    .pocetna--background,
    .nastim--background .clanstvo--background, .donacija--background, .scnd--bck {
     background-attachment: local !important;
     background-position: center;
   }

   .grid--container {
    grid-template-columns: auto auto auto!important;

  }

  .scnd--bck {
    padding: 2em !important;
  }

  .card {
    
    max-width: 10em !important;
    padding: 3px !important;
    position: relative;
  }
  
  .card--image {
    width: 7em !important;
    height: 8em !important;
  }
  
  .card--image img {
    height: 8em !important;
    width: 7em !important;
  }

  .card--text p {
    font-size: 0.6em !important;
  }

  .nasi--clanovi--title {
    font-size: 2em !important;
  }  

  .nasi--clanovi--div {
    z-index: 2;
  }

  .sponsor--container {
    gap: 1em !important;
  }

  .sponsor {
    max-width: 20em;
    max-height: 10em;
  }
  .sponsor img{

    max-width: 20em !important;
}

.arrow-container {
  width: 4em !important;
  margin-top: 0.1em !important;
}
  
.tim--slika--okvir {
  width: 70%;
  height: 70vh;
  padding: 0.5em !important;
  position: relative;
  top: 0;
}

.tim--slika {
  height: auto !important;
}

.nasi--clanovi--div .title {
  padding-top: 0vh;
}


}


@media (min-width: 769px) and (max-width: 1330px) and (orientation: portrait) {
   
  .onama--background {
    background-image: url('./images/Designer4.jpeg') !important;
  }

  .second--bck {
    background-image: url('./images/Designer18m.jpeg') !important;
    background-position: center;
  }

  .third--bck {
    background-image: url('./images/Designer5m.jpg') !important;
    background-position: center;
  }

  .fourth--bck {
    background-image: url('./images/Designer16m.jpeg') !important;
    background-position: center;
  }

  .clanstvo--background {
    background-image: url('./images/Designer3m.jpg') !important;
    background-position: center;
  }

  .opis--o--nama, .news, .donacija--opis, .scroll--container, .form--registration {
    width: 60% !important;
    margin: 0 auto;
    padding-top: 5px;
   }

  .main--title {
    font-size: 6em !important;
  }

  .title {
    padding-top: 24vh !important;
  }

  .navbar--list {
    display: none;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    top: 100%; 
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    min-width: 30%;
    padding: 1rem;
    transition: max-height 0.3s ease-in-out;
    z-index: 12 !important;
  }

  .navbar--list.open {
    display: flex;
    max-height: 700px;
  }

  .navbar .logo {
    width: 4rem;
  }

  
  .main--mid {
    justify-content: center;
    margin: 10px auto 0 auto;
    left: 50% !important;
    transform: translateX(-50%);
   
  }

  .soc--links {
    flex-direction: row !important;
    align-items: center;
    animation: showup 1s;
    padding: 1.1em;
  }

  .soc--links img {
    width: 3em;
  }

   
  .contact {
    padding: 30px !important;
  }

  .contact .logo {
    width: 16em !important;
  }
  
  .pocetna--background,
    .nastim--background .clanstvo--background, .donacija--background, .scnd--bck {
     background-attachment: local !important;
     background-position: center;
   }

 .grid--container {
  grid-template-columns: auto auto auto !important;

}

.nasi--clanovi--title {
  font-size: 2em !important;
}

.nasi--clanovi--div {
  z-index: 2;
  font-size: 3em;
}

.layer3 {
  background-repeat: no-repeat !important;
}

.scnd--bck {
  padding: 2em !important;
}

.card {
  
  max-width: 10em !important;
  padding: 3px !important;
  position: relative;
}

.card--image {
  width: 7em !important;
  height: 8em !important;
}

.card--image img {
  height: 8em !important;
  width: 7em !important;
}

.card--text p {
  font-size: 0.6em !important;
}

.sponsor--container {
  gap: 1em !important;
}

.sponsor {
  max-width: 20em;
  max-height: 10em;
}
.sponsor img{

  max-width: 20em !important;
}

.tim--slika--okvir {
  max-width: 100%;
  height: 50vh;
  padding: 0.5em !important;
  position: relative;
  top: 0;
}

.tim--slika {
  height: auto !important;
}

.nasi--clanovi--div .title {
  padding-top: 0vh;
  font-size: 0.8em;
}


}

@media (min-width: 769px) and (max-width: 1330px) and (orientation: landscape) {
   

  .opis--o--nama, .news, .donacija--opis, .scroll--container, .form--registration {
    width: 60% !important;
    margin: 0 auto !important;
    padding-top: 5px;
   }

  .main--title {
    font-size: 4em !important;
  }

  .title {
    padding-top: 24vh !important;
  }

  .navbar, .scrolled {
    padding: 0.2em 1em;
  }

  .hamburger {
    width: 2em;
    height: 2em;
    font-size: 1em;
  }

  .flag--select {
    height: 1.7em;
    font-size: 0.6em;
  }

  .flag, .flag img {
    width: 1.5em !important;
  }

  .navbar--list {
    display: none;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    top: 100%; 
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    min-width: 30%;
    padding: 1rem;
    transition: max-height 0.3s ease-in-out;
    z-index: 12 !important;
  }

  .navbar--list.open {
    display: flex;
    max-height: 700px;
  }

  .navbar .logo {
    width: 2rem;
  }

  
  .main--mid {
    width: 3em !important;
    transform: translateY(-80%) !important;
  }

  .soc--links {
    padding: 0.5em !important;
  }

  .soc--links img {
    width: 2em;
  }

   
  .contact {
    padding: 30px !important;
  }

  .contact .logo {
    width: 16em !important;
  }
  
  .pocetna--background,
  .nastim--background .clanstvo--background, .donacija--background, .scnd--bck {
   background-attachment: local !important;
   background-position: center;
 }

 .grid--container {
  grid-template-columns: auto auto auto !important;

}

.nasi--clanovi--title {
  font-size: 2em !important;
}

.nasi--clanovi--div {
  z-index: 2;
  font-size: 2em;
  padding-top: 2em;
  padding-bottom: 1em;
}

.scnd--bck {
  padding: 2em !important;
}

.card {
  
  max-width: 10em !important;
  padding: 3px !important;
  position: relative;
}

.card--image {
  width: 7em !important;
  height: 8em !important;
}

.card--image img {
  height: 8em !important;
  width: 7em !important;
}

.card--text p {
  font-size: 0.6em !important;
}

.sponsor--container {
  gap: 1em !important;
}

.sponsor {
  max-width: 20em;
  max-height: 10em;
}
.sponsor img{

  max-width: 20em !important;
}

.stitle {
  margin-bottom: 1em !important;
}

.arrow-container {
  width: 2em !important;
  height: 4em !important;
  margin: 0 auto !important;
}

.tim--slika--okvir {
  width: 45%;
  height: 70vh;
  padding: 0.2em !important;
  position: relative;
  
}

.tim--slika {
  height: auto !important;
}

.nasi--clanovi--div .title {
  padding-top: 0vh !important;
  font-size: 0.9em;
}

}

@media (max-width: 1750px) {

  .hamburger {
    display: flex;
  }

  .navbar--list {
    display: none;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    align-items: center;
    font-size: 1.6em;
    top: 100%; 
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    min-width: 30%;
    padding: 1rem;
    transition: max-height 0.3s ease-in-out;
    z-index: 12 !important;
  }

  .navbar--list.open {
    display: flex;
    max-height: 700px;
  }
}

.soc--links {
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  border-radius: 5px;
  padding: 1em 1em 1em 1em;
  display: flex;
  flex-direction: column;
  animation: titleanim 1.5s;
  position: relative;
}

.soc--ico:hover {
  transform: scale(1.15);
}

body {
  margin: 0;
  overflow-x: hidden;
  text-align: center;
  cursor: url('../public/images/military-gun-white.svg') 10 10, auto;
}

.pocetna--background, .obavjestenja--background, .nastim--background, .clanstvo--background,
.onama--background, .donacija--background {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  transition: 2s;
}

.pocetna--background {
  background-image: url('./images/Designer1.jpeg');  
}

.obavjestenja--background {
  background-image: url('./images/Designer15.jpeg');
}

.nastim--background {
  background-image: url('./images/Designer2.jpeg');
}

.clanstvo--background {
  background-image: url('./images/Designer3.jpeg');
}

.onama--background {
  background-image: url('./images/Designer4.jpeg');
}

.donacija--background {
  background-image: url('./images/Designer8.jpeg');
}

.title {
  margin-top: 0;
  padding-top: 20vh;
  animation: showup 1s, titleanim 2s;
  position: relative;
  color: white;
  text-shadow: 0 0 5px #201717, 0 0 15px #201717, 0 0 20px #201717, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
  font-family: Roboto;
  z-index: 4;
}

.main--main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.main--title {
  font-size: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-style: normal;
  color: black;
  text-shadow: 0 0 5px white, 0 0 15px white, 0 0 20px white, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
}

.title--showup {
  position: relative;
  animation: showup 1s, fromtop 2s, titleglow 8s infinite ease-in-out;
  
}

.title--slidein {
  position: relative;
  animation: showup 1s, tittlebottom 2s, titleglow 8s infinite ease-in-out;
}

.main--mid {
  display: flex;
  align-items: center;
  width: 5em;
  top: 65%;
  left: 0;
  position: absolute;
  
  
}

.form--input {
  display: flex;
  flex-direction: column;
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 20em;
  font-family: Roboto;
  z-index: 1;
}

::placeholder {
  color: rgba(210, 180, 140, 0.6);
}


input:focus {
  border: 2px solid rgb(46, 65, 52);
  outline: none;
  background-color:rgb(243, 242, 203)
}

button:hover {
  background-color: #301e1e;
  color: white;
}

button:active {
  background-image: radial-gradient(#301e1e, black);
}

.input--name {
  position: relative;
  animation: frombottom 1.5s;
  animation-fill-mode: forwards;
}

.input--password {
  position: relative;
  animation: frombottom 2s;
  animation-fill-mode: forwards;
}

h2 {
  font-family: Roboto;
  color: white;
  font-size: 2em;
}

h3 {
  font-size: 1.3em;
  font-family: Roboto;
  color: white;
  text-shadow: 0 0 5px #201717, 0 0 15px #201717, 0 0 20px #201717, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
}

h4 {
  font-size: 0.8em;
  color: white;
  text-shadow: 0 0 5px #201717, 0 0 15px #201717, 0 0 20px #201717, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
}

a {
  color: rgb(214, 214, 110);
  text-decoration: none;
}


.form--registration {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 35%;
  
}

label:nth-child(1) {
  position: relative;
  animation: frombottom 1s;
}

label:nth-child(2) {
  position: relative;
  animation: frombottom 1.1s;
}

label:nth-child(3) {
  position: relative;
  animation: frombottom 1.2s;
}

label:nth-child(4) {
  position: relative;
  animation: frombottom 1.3s;
}

label:nth-child(5) {
  position: relative;
  animation: frombottom 1.4s;
}

label:nth-child(6) {
  position: relative;
  text-align: left;
  animation: frombottom 1.5s;
}

label:nth-child(7) {
  position: relative;
  animation: frombottom 1.6s;
}

.input--registration {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  outline: 0.5px solid tan;
  font-family: Roboto;
  font-weight: 800;
  width: 100%;
  border-radius: 4px;
  border: none;
  height: 2.5em;
  padding: 8px;

}

.input--registration:focus {
  outline: 1px solid tan;
  background-color: rgba(0, 0, 0, 0.8);
}

label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.2em;
  color: white;
  text-shadow: 0 0 5px #201717, 0 0 15px #201717, 0 0 20px #201717, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
  font-size: 1.2em;
  margin: 1.4em 0;
  
}

select {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: Roboto;
  font-weight: 800;
  width: 100%;
  height: 2.5em;
  justify-content: right;
  border-radius: 4px;
}

textarea {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  outline: 0.5px solid tan;
  padding: 8px;
  vertical-align: top;
  line-height: 1.5;
  height: 6em;
  width: 100%;
  border-radius: 4px;
  font-family: Roboto;
  font-weight: 800;
  resize: none;
}

textarea:focus {
  background-color: rgba(0, 0, 0, 0.8);
}

.button--registration {
  position: static;
  width: 16em;
  margin: 2em auto 4em auto;
  position: relative;
  animation: showup 2s;
  animation-fill-mode: forwards;
  background-color: tan;
}

.o--nama--main {
  overflow-x:hidden;
}

.opis--o--nama {
  margin: 3em auto;
  width: 35%;
  text-align: left;
}

p {
  color: white;
  font-family: Roboto;
  font-size: 1.2em;
  font-weight: 700;
  text-shadow: 0 0 5px #201717, 0 0 15px #201717, 0 0 20px #201717, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
}

.opis--o--nama p:nth-of-type(1) {
  animation: showup 0.5s ease-in-out;
  position: relative;
}
.opis--o--nama p:nth-of-type(2) {
  animation: showup 0.6s ease-in-out;
  position: relative;
}
.opis--o--nama p:nth-of-type(3) {
  animation: showup 0.7s ease-in-out;
  position: relative;
}




.div--split0 {
  background-color: #2b1f1f;
  width: 100%;
  height: 3em; 
  outline: 0.8em solid;
  outline-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
}

.div--split {
  display: flex;
  align-items: center;
  padding: 2em 0;
  background-color: #2b1f1f;
  width: 100%;
  min-height: 26em;
  
  outline: 0.8em solid;
  outline-color: rgba(0, 0, 0, 0.5);
}

.second--bck, .third--bck {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
}

.second--bck {
  background-image: url('./images/Designer7.jpeg');
  height: 40em;
}

.third--bck {
  background-image: url('./images/Designer9.jpeg');
  height: 40em;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #2b1f1f;
  width: 100%;
  min-height: 30em;
  outline: 10px solid;
  outline-color: rgba(0, 0, 0, 0.5);
  font-family: Roboto;
  padding: 30px 200px;
  color: tan;
  
}

.info {
  text-align: left;
  line-height: 2rem;
}

.contact .logo { 
  width: 25em;
  cursor: pointer;
}

.contact p {
  line-height: 2rem;
  color: tan;
  text-shadow: none;
}

.scroll--container {
  background-color: #2b1f1f;
  padding: 1.5em;
  outline: 0.8em solid rgba(0, 0, 0, 0.5);
  min-height: 28em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  width: 35%;
  margin: 0 auto;
  position: relative;
  z-index: 3;
}

.hidden--title, .hidden--p0, .hidden--p1 {
  opacity: 0; 
  transform: translateY(100px); 
}

.hidden--title {
  transition: opacity 0.7s ease-out, transform 0.7s ease-out; 
}

.hidden--p0 {
  transition: opacity 1.2s ease-out, transform 1.2s ease-out;
}

.hidden--p1 {
  transition: opacity 1.7s ease-out, transform 1.7s ease-out;
}

.visible {
  opacity: 1; 
  transform: translateY(0); 
  color: white;
  text-shadow: 0 0 5px #201717, 0 0 15px #201717, 0 0 20px #201717, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
  font-family: Roboto;
}

.flag--selector {
  display: flex;
  align-items: center;
  margin-left: 1em;
  justify-content: center;
}

.flag--container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.flag--select {
  border: none;
  margin-right: 0.5em;
}

.flag, .flag img {
  width: 2.5em;
}


.main--submitted {
  padding-top: 20vh;
  font-size: 2em;
}

.hidden {
  display: none;
}

div .hidden {
  max-width: 25%;
  font-family: Roboto;
}

.grid--container {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: auto auto auto auto;
  gap: 5em;
  position: relative;
  z-index: 3;
  scroll-behavior: smooth;
}

.card {
  background-color: rgba(0, 0, 0, 0.7);
  width: 15em;
  min-width: 10em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  backdrop-filter: blur(3px);
  padding: 10px;
  position: relative;
}

.card--image {
  width: 12em;
  height: 14em;
  overflow: hidden;
  border: 2px solid black;
  box-shadow: 0 0 5px tan;
  border-radius: 5px;
}

.card--image img {
  height: 14em;
  width: 12em;
  object-fit: cover;
  object-position: 50% 30%;
}

.card--text h3{
  color: black;
  text-shadow: 0 0 5px tan, 0 0 6px white, 0 0 7px tan, 
               0 0 3px rgb(168, 148, 105), 0 0 4px rgb(168, 148, 105), 0 0 5px rgb(168, 148, 105);
  font-family: "Yeseva One", serif;
}

.card--text p {
  font-weight: 300;
  font-size: 1em;
  line-height: 0.5em;
}

.card:hover {
  transition-duration: 150ms;
  box-shadow: 0 5px 20px 5px #00000044;
}

.card .glow {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

}

.nasi--clanovi--div {
  height: 100vh;
  margin: 0;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  scroll-behavior: smooth;
}

.nasi--clanovi--title {
 
  font-weight: 400;
  font-style: normal;
  color: black;
  text-shadow: 0 0 5px white, 0 0 15px white, 0 0 20px white, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
  font-family: "Yeseva One", serif;
  font-size: 6em;
  animation: fromtop 1s;
  z-index: 4;
  position: relative;
}


.arrow-container {
  display: block;
  position: relative;
  margin: 2em auto 0 auto;
  width: 7em;
  height: 7em;
  z-index: 3;
  animation: showup 1s;
}

.arrow-container:hover {
  cursor: pointer;
}
.arrow-container:hover .arrow {
  top: 50%;
}
.arrow-container:hover .arrow:before {
  transform: translate(-50%, -50%) rotateZ(-30deg);
}
.arrow-container:hover .arrow:after {
  transform: translate(-50%, -50%) rotateZ(30deg);
}

.arrow {
  position: absolute;
  left: 50%;
  transition: all 0.4s ease;
}
.arrow:before,
.arrow:after {
  transition: all 0.4s ease;
  content: '';
  display: block;
  position: absolute;
  transform-origin: bottom right;
  background: #000000;
  box-shadow: 0 0 5px white, 0 0 15px white, 0 0 20px white, 
               0 0 5px rgb(94, 92, 0), 0 0 15px rgb(94, 92, 0), 0 0 25px rgb(94, 92, 0);
  width: 4px;
  height: 30px;
  border-radius: 10px;
  transform: translate(-50%, -50%) rotateZ(-45deg);
}
.arrow:after {
  transform-origin: bottom left;
  transform: translate(-50%, -50%) rotateZ(45deg);
}

.arrow:nth-child(1) {
  opacity: 0.3;
  top: 35%;
}
.arrow:nth-child(2) {
  opacity: 0.6;
  top: 55%;
}
.arrow:nth-child(3) {
  opacity: 0.9;
  top: 75%;
}

.scnd--bck {
 /* background-image: url('./images/Designer2.jpeg');
  filter: sepia(50%);
  z-index: 0;
  background-size: cover;
  background-attachment: fixed;*/
  padding: 20em;
  position: relative;
  margin-top: 100vh;
}

.fourth--bck {
  background-image: url('./images/Designer14.jpeg');
  background-size: cover;
  background-attachment: fixed;
  height: 70em;
}

.div--split--clouds {
  background-position: center;
  position: fixed;
  height: 100vh;
  top: 100%;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.layer1 {
  background-image: url('./images/jiz.png');
  background-repeat: no-repeat;
  background-size: cover;
  filter: opacity(55%);
  z-index: 5;
}

.layer2 {
  background-image: url('./images/yk.png');
  background-repeat: no-repeat;
  background-size: cover;
  filter: opacity(45%);
  z-index: 6;
}

.layer3 {
  background-image: url('./images/pp.png');
  background-repeat: repeat;
  background-size: contain;
  filter: opacity(75%);
  z-index: 7;
}

.main--nastim {
  position: relative;
}

.scroll--clouds {
  height: 100vh;       /* Ceo ekran zauzet */
  position: relative;  /* Oblak prelazi preko njega */
  
}

.news {
  position: relative;
  animation: frombottom 1s ease-in-out, showup 1s;
  text-align: center;
  width: 35%;
  z-index: 4;
}

.news--main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sponsor--container {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  margin: auto;
  gap: 5em;
}

.main--sponsors {
  position: relative;
}

.donacija--div {
  margin-top: 100vh;
  position: relative;
  height: 100vh;
}

.donacija--opis {
  margin: 3em auto 0 auto;
  width: 35%;
  text-align: left;
  position: relative;
  z-index: 3;
}

.sponsor {
  width: 28em;
  height: 15em;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sponsor img {
  width: 28em;
}

.sponsor h3 {
  margin-bottom: 1.5em;
}

.sponsors--div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  animation: showup 1s ease-in-out;
}

.stitle {
  margin-bottom: 5em;
}

.scroll--parallax {
  height: 100vh;
  position: relative;
}

.parallax {
  position: fixed;
  /*overflow-y: scroll;*/
  height: 100vh;
  width: 100%;
  top: 100%;
  left: 0;
}

.para1 {
  background-image: url('./images/para1.png');
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.para2 {
  background-image: url('./images/para2.png');
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.para3 {
  background-image: url('./images/para3.png');
  background-size: cover;
  height: 100vh;
  width: 100%;
}

.slike--div {
  height: 100vh;
  margin-top: 100vh;
}

.tim--slika--okvir {
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1em;
  overflow: hidden;
  border-radius: 5px;
}

.tim--slika {
  background-image: url('../public/sadrzaj/clanovi-tima/slike/tim-slika.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 55vh;
  width: 800px;
  animation: showup 1s;
}